import React, { useEffect, useState } from 'react';
import { Link } from "gatsby"

import MenuButton from './menu-button'
import * as navigationBarStyles from "./navigation-bar.module.css"

const NavigationBar = () => {
  
  const [navBackground, setNavBackground] = useState('')
  const navRef = React.useRef()
  navRef.current = navBackground
  useEffect(() => {
      const handleScroll = () => {
          const show = window.scrollY > 20
          if (show) {
              setNavBackground(`${navigationBarStyles.nav_bar_active}`)
          } else {
              setNavBackground('')
          }
      }

      document.addEventListener('scroll', handleScroll)
      return () => {
        document.removeEventListener('scroll', handleScroll)
      }

  }, [])

  const [menuButtonToggle, setMenuButtonToggle] = useState(`${navigationBarStyles.drop_down_menu_inactive}`)
  const menuButtonRef = React.useRef()
  menuButtonRef.current = menuButtonToggle

  const onClick = () => {
    if (menuButtonToggle === `${navigationBarStyles.drop_down_menu_inactive}`) {
      setMenuButtonToggle(`${navigationBarStyles.drop_down_menu_active}`)
    } else {
      setMenuButtonToggle(`${navigationBarStyles.drop_down_menu_inactive}`)
    }
  }

  const handleCloseMenu = () => {
    setMenuButtonToggle(`${navigationBarStyles.drop_down_menu_inactive}`)
  }

  return (
    <>
      <header className={`${navigationBarStyles.nav_bar} ${navRef.current}`}>
        <div className={navigationBarStyles.header_view}>
          <div className={navigationBarStyles.hero_header_container}>
            <div className={navigationBarStyles.nav_bar_header}>
              <Link to="/#" className={navigationBarStyles.hero_logo_text}>To Know Me</Link>
              <Link to="/#how-to-play" className={navigationBarStyles.nav_bar_link}>How To Play</Link>
              <MenuButton handleClick={onClick}/>
            </div>
            <div className={`${navigationBarStyles.drop_down_menu} ${menuButtonRef.current}`}>
              <ul className={navigationBarStyles.nav_bar_list}>
                <li>
                  <Link to="/#how-to-play" onClick={handleCloseMenu} className={navigationBarStyles.nav_bar_menu_link}>How To Play</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default NavigationBar
