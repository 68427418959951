import React from "react"

import * as footerStyles from './footer.module.css'

const Footer = () => {

  return (
    <footer className={footerStyles.footer}>
        <div className={footerStyles.footer_block}>
          <p className={footerStyles.legal_block}>
            <a href="terms">
              Terms of Service
            </a>
            |
            <a href="privacy">
              Privacy Policy
            </a>
            | © 2021 To Know Me
          </p>
          <a className={footerStyles.contact_link} href="mailto:get@toknowme.app">get@toknowme.app</a>
        </div>
    </footer>
  )
}

export default Footer
