import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadataImage = () => {
  const image = useStaticQuery(graphql`
    query SITE_METADATA_IMAGE_QUERY {
      image: file(relativePath: { eq: "thumbnail.png" }) {
        childImageSharp {
          fixed(width: 800) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return image
}

export default useSiteMetadataImage
