import React from 'react';

import * as menuButtonStyles from "./menu-button.module.css"

const MenuButton = ({ handleClick }) => {

    return (
        <>
        <button className={menuButtonStyles.nav_bar_menu_button} onClick={handleClick}>
            <span className={menuButtonStyles.icon_bar}></span>
            <span className={`${menuButtonStyles.icon_bar} ${menuButtonStyles.icon_bar_bottom}`}></span>
            <span className={`${menuButtonStyles.icon_bar} ${menuButtonStyles.icon_bar_bottom}`}></span>
        </button>
        </>
    )
}
  
export default MenuButton