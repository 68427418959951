import React from 'react'
import Footer from './footer'
import NavigationBar from './navigation-bar'
import SEO from './seo.js'
import useSiteMetadataImage from './metadata-image'

const TemplateWrapper = ({ children }) => {
  const { image } = useSiteMetadataImage();

  return (
    <div>
      <SEO title="To Know Me" image={image?.childImageSharp?.fixed.src} />
      <NavigationBar />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
